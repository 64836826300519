import { i18n } from "@lingui/core";
import { Group, Select } from "@mantine/core";
import {
	useFetcher,
	useFetchers,
	useMatches,
	useRouteLoaderData,
} from "@remix-run/react";

export async function loadCatalog(locale: string) {
	const { messages } = await import(`../../locales/${locale}.po`);

	return i18n.loadAndActivate({ locale, messages });
}

export function useLocale(localeKey = "locale"): string {
	const [rootMatch] = useMatches();
	const { [localeKey]: locale } =
		(rootMatch.data as Record<string, string>) ?? {};
	if (typeof locale === "string") return locale;
	return "id"; // fallback to id
}

export function useOptimisticLocale() {
	const fetchers = useFetchers();
	const themeFetcher = fetchers.find((f) => f.formAction === "/");

	if (themeFetcher?.formData) {
		const submission = Object.fromEntries(themeFetcher.formData);

		// Use Valibot or zod to validate
		if (
			submission.status === "success" &&
			typeof submission.value === "object" &&
			"locale" in submission.value
		) {
			return submission.value.locale as string;
		}
	}
}

export function getLanguages() {
	return [
		{
			value: "en",
			label: "English",
		},
		{
			value: "id",
			label: "Indonesia",
		},
		{
			value: "jp",
			label: "Jepang",
		},
	];
}

function renderOption({
	option,
}: { option: { label: string; value: string } }) {
	return (
		<Group flex="1" gap="xs">
			{option.label}
			<img className="w-6" src={`/icons/flag/${option.value}.svg`} />
		</Group>
	);
}

export function LocaleSelector() {
	const languages = getLanguages();
	const { locale, setLocale } = useLocaleSelector();

	return (
		<Select
			renderOption={renderOption}
			checkIconPosition="left"
			value={locale}
			onChange={(v) => setLocale(v ?? "id")}
			data={languages}
			name="locale"
		/>
	);
}

export function useLocaleSelector() {
	const data = useRouteLoaderData<{ locale: string }>("root");
	const fetcher = useFetcher();

	const optimisticLocale = useOptimisticLocale();
	const locale = optimisticLocale ?? data?.locale ?? "id";

	const setLocale = (locale: string) => {
		fetcher.submit(
			{
				locale,
			},
			{
				method: "POST",
				action: "/",
			},
		);
	};

	return {
		locale,
		setLocale,
	};
}
